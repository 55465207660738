import React from "react"
import s from 'styled-components'

import Post from '../components/post'
import Layout from "../components/layout"
import { Text } from "../data/typography"
const midfatherText = (
  <Text>
    중시조(中始祖)- 1세 성주공(星主公) 휘(諱 )말로 공(末老 公)<br />

    수작(受爵) 성주(星主). 수봉(受封) 정제군(定濟君). 제주고씨(濟州髙氏) 중시조(中始祖).<br />

    탐라국(眈羅國) 시조 고을나(髙乙那)왕의 사십육세손이며 탐라의 마지막 군주인 제사십오세 고자견(髙自堅)왕의 태자(太子)이며 천성이 고결(高潔)하고 영민(靈敏)하여 덕망이 높았다고 한다.<br />

    935[을미(乙未)]년에 고려 태조 왕건(王建)이 신라와 후백제를 정복한 다음 그 여세로서 탐라국도 강제로 예속(隸屬)하려고 시도하였다. 탐라국(眈羅國) 고자견(髙自堅)왕은 고려 태조에 사신(使臣)을 보내 화친(和親)을 꾀하려 했으나 대세(大勢)에 눌리어 굴복하고 말았으니 938년태조[太祖)21년 무술(戊戌)] 12월에 태자(太子)인 공을 고려에 입조(入朝)케 하였다.<br />

    이에 고려 태조는 탐라국(眈羅國)의 오랜 왕통(王統)과 교빙(交聘)을 존중히 여겨 접대하는 범절을 임금과 같게 하여 각별히 예우(禮遇)하고 공에게 성주(星主), 왕자(王子)의 작호를 제수(除授)하였으며 그 작위를 대대로 직계(直系) 후손(後孫)에게 세습(世襲)케 하였다.<br />

    太祖 二十一年 冬十二月 眈羅國太子 末老來朝 賜 星主.王子 爵.(高麗史 世家 卷 二)<br />

    태조 이십일년 동십이월 탐라국태자 말로내조 사 성주. 왕자 작.(고려사 세가 권 이)<br />

    고려 태조는 탐라국의 지리적(地理的), 역사적(歷史的)특수성을 참작하여 왕의 칭호 대신 성주라 칭하게 하였을 뿐 계속 자치국(自治國)으로 인정하였으니 이로부터 공이 탐라국주(眈羅國主)로서 제 일대(一代) 성주가 되어 탐라를 다스렸다.<br />

    탐라는 오랜 왕치국(王治國)으로 내려오는 동안 신라, 고구려, 백제, 당, 일본 등 제국과 교역(交易)이 빈번하여 짐에 따라 도읍지(都邑地)인 칠성대촌[七星大村:濟州市(제주시)]은 탐라국의 관문이며 중심지로서 자연히 번성 해 졌으며 전각(殿閣) 등을 중수하고 축성(築城) 하였다고 한다. 공은 탐라성주(眈羅星主)로서 토산물(土産物)의 생산을 장려하고 고려와 교빙(交聘)하는 한편 송(宋), 일본 등과 교역하였다.<br />

    그후 목송(穆宋)때인 1002년[임인(壬寅))과 1007년(정미(丁未)] 두 차례에 걸쳐서 탐라의 화산폭발로 오,육일 동안이나 한라산 용암이 분출하고 운무(雲霧)가 하늘을 가리워 밤과 같았으며 지동(地動)은 우뢰와 같았으니 이때 해중(海中)에서 비양도(飛揚島)와 서산[瑞山:軍山(군산)]이 솟아 나왔다. 이때 목종(穆宗)은 태학(太學) 박사(博士)인 전공지(田拱之)를 탐라에 보내어 화산폭발로 인한 인명 및 재산의 피해 상황과 해중에서 새로 솟아난 섬들의 지형도(地形圖)를 그려 조사케 하였다.<br />

    1011년[현종(顯宗) 2年 신해(辛亥)]9월에 공은 고려왕으로부터 주기[朱記:朱印(주인)]을 받아 이를 성주 직분의 증표(證票)로 삼았다고 한다. 탐라는 섬나라로서 일찍이 배를 만들고 다루는 기술이 발달하였고 한라산에는 재목이 풍부하였으므로 다음해 대선(大船) 1척을 만들어 고려에 보냈다.<br />

    1019년[현종(顯宗) 10年 을미(乙未)] 9월 중양절(重陽節)에 공은 수행원을 대동하고 송(宋), 흑수국(黑水國) 사신과 함께 고려 왕경(王京)에 입경하여 왕의 저관[(王宮(왕궁)]에서 베푼 연회에 참석하였다.<br />

    그후 1022년[현종(顯宗) 13年 임술(壬戌)]2월에 공은 노쇠한 몸이 되니 셋째 아들인 소(紹)를 사자(使者)와 함께 고려에 보내어 方物(방물)을 바쳤다.<br />

    오랜 왕통의 안정된 정치적 기반 위에 성주인 공이 선정(善政)을 함으로서 계속 태평성대를 구가하였으니 이는 고려에 예속된 후에도 자주권(自主權)을 잃지 않고 스스로 개척하고 헌신한 결과라 하겠다.<br />

    공은 아들 삼형제를 두었으니 장남 유(維)는 좌복사(左僕射), 삼남 소(紹)는 개성부도총독(開城莩總督)등 높은 벼슬을 지냈고 장남 유(維)로 하여금 성주작(星主爵)을 계승케 하였다.<br />

    탐라국 태자로서 처음으로 고려에 입조하여 성주작(星主爵)을 받았으므로 공을 제주고씨(濟州髙氏)의 중시조(中始祖) 일세(一世)로 삼고있다.<br />

    (참고문헌:參考文獻) : 삼국유사(三國遺事), 영주지(瀛洲誌), 고려사(高麗史), 고려사절요(高麗史節要), 증보문헌비고(增補文獻備), 동국여지승람(東國與地勝覽), 한국사(韓國史), 동문선(東文選)<br />

    [출처] 고씨 시조 고말로(성주공) – 1세(중시조) 수작(受爵) 성주(星主). 受封(수봉) 정제군(定濟君). 제주고씨(濟州髙氏) 중시조(中始祖) . (경주이씨(慶州李氏)시조(始祖)표암공(瓢巖公)이알평(李謁平))<br />
  </Text>
)
const MidfatherPage = () => (
  <Layout pageTitle={'역 사'} pageSubtitle={'중시조 고말로'}>
    <Post imageUrl={'/고말로.jpg'} text={midfatherText} />
  </Layout>
)

export default MidfatherPage
